<template>

  <div>

    <!-- Filters -->
    <surveys-list-filters :type-filter.sync="typeFilter" :reviewer-filter.sync="reviewerFilter"
      :reviewee-filter.sync="revieweeFilter" :status-filter.sync="statusFilter" :type-options="typeOptions"
      :reviewer-options="reviewerOptions" :reviewee-options="revieweeOptions" :status-options="statusOptions" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="limit" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refSurveyListTable" class="position-relative" :items="fetchSurveys" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="sortOrderSync">

        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Loading..."></b-spinner>
          </div>
        </template>
        <!-- Column: Title -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <b-link :to="{ name: 'survey-view', params: { id: data.item._id } }"
              class="font-weight-bold d-block text-nowrap lead">
              {{ data.item.body.title }}
            </b-link>
            <span class="text-secondary h5">{{ data.item.body.description }}</span>
          </b-media>
        </template>

        <!-- Column: Reviewer -->
        <template #cell(reviewer)="data">
          <b-media v-if="data.item.reviewer" vertical-align="center">
            <b-link class="font-weight-bold d-block text-nowrap text-success">
              {{ data.item.reviewer.fullName }}
            </b-link>
            <span class="text-secondary">{{ data.item.reviewer.email }}</span>
          </b-media>
          <span v-else><b-badge variant="light-secondary">N/A</b-badge> </span>
        </template>


        <!-- Column: Reviewee -->
        <template #cell(reviewee)="data">
          <b-media v-if="data.item.reviewee" vertical-align="center">
            <b-link class="font-weight-bold d-block text-nowrap text-info">
              {{ data.item.reviewee.fullName }}
            </b-link>
            <span class="text-secondary ">{{ data.item.reviewee.email }}</span>
          </b-media>
          <span v-else><b-badge variant="light-secondary">N/A</b-badge> </span>
        </template>

        <!-- Column: Engagement -->
        <template #cell(engagement)="data">
          <span v-if="data.item.engagement" class="font-weight-bold d-block text-nowrap ">
            {{ data.item.engagement }}
          </span>
          <span v-else><b-badge variant="light-secondary">N/A</b-badge> </span>
        </template>


        <!-- Column: Type -->
        <template #cell(type)="data">
          <span>
            <b-badge :variant="resolveSurveyTypeVariant(data.item.type)">
              <feather-icon :icon="resolveSurveyTypeIcon(data.item.type)" class="mr-25" />
              <span class="align-text-top text-capitalize"> {{ resolveSurveyTypeText(data.item.type) }}</span>
            </b-badge>
          </span>
        </template>

        <!-- Column: isSubmitted -->
        <template #cell(isSubmitted)="data">
          <span
            :class="data.item.isSubmitted ? 'status-dot status-dot--active' : 'status-dot status-dot--inactive'"></span>
        </template>

        <!-- Column: Actions -->
        <!-- <template #cell(actions)="data">
          <b-link :to="{ name: 'survey-edit', params: { id: data.item._id, item: data.item } }">
            <feather-icon icon="EditIcon" class="edit-button ml-1" />
          </b-link>
          <b-link :to="{ name: 'survey-view', params: { id: data.item._id, item: data.item } }">
            <feather-icon icon="FileTextIcon" class="details-button ml-50" />
          </b-link>
        </template> -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="page" :total-rows="totalSurveys" :per-page="limit" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { ref, onUnmounted, computed, onMounted, getCurrentInstance } from '@vue/composition-api'
import SurveysListFilters from './SurveysListFilters.vue'
import useSurveysList from './useSurveysList'
import surveyStoreModule from '../surveyStoreModule'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    SurveysListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SURVEY_APP_STORE_MODULE_NAME = 'app-survey'
    const reviewerOptions = ref([])
    const revieweeOptions = ref([])
    const typeOptions = [
      { label: 'Self Evaluation', value: 'selfEval' },
      { label: 'Peer Evaluation', value: 'eval360' },
      { label: 'End of Project Evaluation', value: 'projectEval' },
    ]
    const statusOptions = [
      { label: 'Submitted', value: 'submitted' },
      { label: 'Pending', value: 'pending' },
    ]


    const { proxy } = getCurrentInstance()

    // Register module
    if (!store.hasModule(SURVEY_APP_STORE_MODULE_NAME)) store.registerModule(SURVEY_APP_STORE_MODULE_NAME, surveyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SURVEY_APP_STORE_MODULE_NAME)) store.unregisterModule(SURVEY_APP_STORE_MODULE_NAME)
    })

    // Fetch locationOptions
    onMounted(() => {
      proxy.$http.get('/users')
        .then(response => {
          const users = response.data.users
          reviewerOptions.value = users.map(user => ({ label: user.fullName, value: user._id }))
          revieweeOptions.value = users.map(user => ({ label: user.fullName, value: user._id }))
        })
        .catch(error => console.error('Error fetching location options:', error))
    })

    const {
      fetchSurveys,
      tableColumns,
      limit,
      page,
      totalSurveys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      refSurveyListTable,
      refetchData,

      // UI
      resolveSurveyTypeVariant,
      resolveSurveyTypeIcon,
      resolveSurveyTypeText,
      resolveDepartmentVariant,

      // Extra Filters
      typeFilter,
      reviewerFilter,
      revieweeFilter,
      statusFilter,
    } = useSurveysList()

    const sortOrderSync = computed({
      get: () => sortOrder.value === 'desc',
      set: (newVal) => {
        sortOrder.value = newVal ? 'desc' : 'asc'
      }
    })



    return {


      fetchSurveys,
      tableColumns,
      limit,
      page,
      totalSurveys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      sortOrderSync,
      refSurveyListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveSurveyTypeVariant,
      resolveSurveyTypeIcon,
      resolveSurveyTypeText,
      resolveDepartmentVariant,
      resolveDepartmentVariant,

      typeOptions,
      reviewerOptions,
      revieweeOptions,
      statusOptions,

      // Extra Filters
      typeFilter,
      reviewerFilter,
      revieweeFilter,
      statusFilter,
    }
  },
}
</script>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dot--active {
  background-color: rgb(0, 165, 0);
}

.status-dot--inactive {
  background-color: rgba(255, 0, 0, 0.76);
}

.edit-button {
  color: $primary;
  cursor: pointer;
}

.details-button {
  color: $primary;
  cursor: pointer;
}
</style>
