import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSurveys(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/surveys', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSurvey(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/surveys/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editSurvey(ctx, {id, surveyData}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/surveys/${id}`, surveyData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSurvey(ctx, surveyData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/surveys', surveyData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
